import React, { useCallback, useMemo } from 'react';
import { globalState } from 'shared/foreground/models';
import { updateDefaultPageSetting } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/settings';
import { DefaultPage } from 'shared/types';
import capitalize from 'shared/utils/capitalize';

import styles from './DefaultViewDropdown.module.css';
import { DropdownOptionType } from './Dropdown';
import SelectDropdown from './SelectDropdown';

export default function DefaultViewDropdown({ triggerClassName = '' }: { triggerClassName?: string; }) {
  const defaultPage = globalState(useCallback((state) => state.persistent.settings.defaultPage, []));
  const getDefaultPageLabel = (defaultPageKey: DefaultPage) => {
    if (defaultPageKey === DefaultPage.CurrentlyReading) {
      return 'Currently reading';
    }

    return capitalize(defaultPageKey);
  };

  const options = useMemo(
    () =>
      Object.keys(DefaultPage).map((key) => ({
        type: DropdownOptionType.Item,
        name: getDefaultPageLabel(DefaultPage[key]),
        checked: defaultPage === DefaultPage[key],
        onSelect: () => {
          updateDefaultPageSetting(DefaultPage[key], { userInteraction: 'click', showToast: true });
        },
      })),
    [defaultPage],
  );

  return (
    <SelectDropdown
      options={options}
      triggerClassName={styles.trigger}
      selectedOption={defaultPage ? getDefaultPageLabel(defaultPage) : 'Library'}
    />
  );
}
